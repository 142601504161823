<template>
  <div v-if="page_loading">
      <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />

    <v-card elevation="1" class="mb-7">
      <v-card-title style="height: auto;">
        <h4>การเงิน (สาขา)</h4> <v-icon>mdi-chevron-right</v-icon>
        <span class="font-weight-regular body-1" v-if="datalist.typeInvoice == 1"> ใบแจ้งหนี้ขนส่ง </span>
        <span class="font-weight-regular body-1" v-if="datalist.typeInvoice == 2"> ใบแจ้งหนี้ค่าบริการ COD </span>
        <v-icon>mdi-chevron-right</v-icon>
        <span class="font-weight-regular body-1"> รายละเอียดใบแจ้งหนี้ของ สาขา {{ datalist.branchName }} </span>
        <v-spacer/>

        <!-- <v-btn v-if="loadingExcel == 0" outlined color="success" @click="loading_excel_percent()"><v-icon color="success" left>fa-file-excel</v-icon> <h4 class="pl-2">export to excel</h4></v-btn>
        <v-btn v-else outlined color="info" ><v-icon color="info" left>fa-file-excel</v-icon> <h4 class="pl-2">
          export to excel
          <span>({{loadingExcel}}%)
            <v-progress-circular indeterminate
              color="info"
              size="25"
            ></v-progress-circular> </span>
        </h4></v-btn> -->

        <vue-excel-xlsx
          :data="datalist.tracklist"
          :columns="excel_detail_invoice_list_header"
          :file-name="filename"
          :sheet-name="'รายงานข้อมูลใบแจ้งหนี้'"
        >
          <v-btn color="success" dark @click="exportexceltracklist(datalist.typeInvoice)" class="fn-14" outlined>
              Export Excel
          </v-btn>
        </vue-excel-xlsx>

        <!-- <v-btn outlined v-if="datalist.length > 0" @click="PrintPreview()" ><v-icon>mdi-printer</v-icon> <h4 class="pl-2">ปริ้นใบแจ้งหนี้</h4> </v-btn> -->
      </v-card-title>
    </v-card>
    <v-card class="elevation-0 mt-2">
      <v-card-title>
       <font class="hidden-xs-only pt-3" v-if="datalist != null">ข้อมูลใบแจ้งหนี้ : {{ datalist.invoiceNo }} (เวลาออกใบเสร็จ {{ datalist.invoiceDate }})</font>
      </v-card-title>
      <v-card-text>
        <!-- PC -->
        <v-data-table
          :headers="headers_express"
          :items="datalist.tracklist"
          hide-default-footer :items-per-page="100000"
          class="elevation-0 packhai-border-table"
          :mobile-breakpoint="0"
          v-if="datalist.typeInvoice == 1"
          >
          <template v-slot:item.num="{ item }">
            <!-- <span v-if="datalist.indexOf(item) < datalist.length-1">{{ datalist.indexOf(item) + 1 }}</span> -->
            {{ gen_num_data_table(datalist.tracklist.indexOf(item)) }}
          </template>
          <template v-slot:item.trackingNo="{ item }">
            {{ item.tracking }}
          </template>
          <template v-slot:item.size="{ item }">
            <span v-if="item.cubic1!=null && item.cubic2!=null && item.cubic3!=null">
                {{item.cubic1+" x "+ item.cubic2+" x "+ item.cubic3}}
            </span>
          </template>
          <template v-slot:item.expressAmount="{ item }">
            {{format_price(item.expressAmount)}}
          </template>

        </v-data-table>
        <v-data-table
          :headers="headers_cod"
          :items="datalist.tracklist"
          hide-default-footer :items-per-page="100000"
          class="elevation-0 packhai-border-table"
          :mobile-breakpoint="0"
          v-else-if="datalist.typeInvoice == 2"
          >
          <template v-slot:item.num="{ item }">
            <!-- <span v-if="datalist.indexOf(item) < datalist.length-1">{{ datalist.indexOf(item) + 1 }}</span> -->
            {{ gen_num_data_table(datalist.tracklist.indexOf(item)) }}
          </template>
          <template v-slot:item.trackingNo="{ item }">
            {{ item.tracking }}
          </template>
          <template v-slot:item.size="{ item }">
            <span v-if="item.cubic1!=null && item.cubic2!=null && item.cubic3!=null">
                {{item.cubic1+" x "+ item.cubic2+" x "+ item.cubic3}}
            </span>
          </template>
          <template v-slot:item.codAmount="{ item }" >
            {{item.codAmount}}
          </template>

        </v-data-table>
        <v-row class="mx-0 my-2"></v-row>
        <!-- <v-row  class="mx-0 my-5">
          <v-col cols="12" align="center">
            <div class="text-center" @click="changePage()">
              <v-pagination
                v-model="page"
                :length="pageLength"
                :total-visible="10"
                color="primary"
              ></v-pagination>
            </div>
          </v-col>
        </v-row> -->
        <v-divider/>
        <v-row justify="end" v-if="datalist != null" v-show="true">
            <v-col lg="4">
                <v-row class="py-3 px-4">
                    <v-col>
                       ราคา
                    </v-col>
                    <v-col cols="4" lg="2" align="right">
                        <span v-if="datalist.calculatedPrice != null">{{ format_price(datalist.calculatedPrice) }}</span>
                        <span v-else>0.00</span>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="py-3 px-4">
                    <v-col>
                        <span v-if="datalist.vatType == 1">ภาษีมูลค่าเพิ่ม (No Vat)</span>
                        <span v-else-if="datalist.vatType == 2">ภาษีมูลค่าเพิ่ม (VAT Included)</span>
                    </v-col>
                    <v-col cols="4" lg="2" align="right">
                        <span v-if="datalist.vatType == 2">{{ format_price(datalist.vatAmount) }}</span>
                        <span v-else-if="datalist.vatType == 1">0.00</span>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="py-3 px-4 font-weight-bold">
                    <v-col>
                        รวมยอดทั้งหมด
                    </v-col>
                    <v-col cols="4" lg="2" align="right">
                        <span>{{ format_price(datalist.totalPrice) }}</span>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
            </v-col>
        </v-row>
      </v-card-text>
    </v-card>

  </div>
</template>

<script>
  import axios from 'axios'
  import { header_token } from '@/website/token'
  import Loading from '@/website/components/Loading'
  import {  reportService_dotnet, branchService_dotnet } from '@/website/global'
  import { AlertError } from '@/website/global_alert'
  import { FormatInvoiceBranch, format_price, FormatOrder } from '@/website/global_function'
  export default {
    components: {
      Loading
    },
    data: () => ({
      page_loading: true,
      loading: false,
      headers_express: [
                          { text: '', align: 'center', value: 'num', sortable: false, width: '5%' },
                          { text: 'TrackingNo', align: 'left', value: 'trackingNo', sortable: false,  },
                          { text: 'ขนาด', align: 'center', value: 'size', sortable: false,  },
                          { text: 'ค่าขนส่ง',align: 'right', value: 'expressAmount', sortable: false  },
                      ],
      headers_cod: [
                      { text: '', align: 'center', value: 'num', sortable: false, width: '5%' },
                      { text: 'TrackingNo', align: 'left', value: 'trackingNo', sortable: false,  },
                      { text: 'ขนาด', align: 'center', value: 'size', sortable: false,  },
                      { text: 'ค่าบริการ cod',align: 'right', value: 'codAmount', sortable: false  },
                    ],
      datalist: [],
      datalist_for_excel: [],

      data_group_detail: null,

      // excel
      excel_detail_invoice_list_header: [
        { label: "TrackingNo", field: "tracking", width: 15},
        { label: "กว้าง", field: "cubic1", width: 25 },
        { label: "ยาว", field: "cubic2", width: 25 },
        { label: "สูง", field: "cubic3", width: 15 },
        { label: "ค่าบริการ cod", field: "codAmount", width: 15 },
      ],
      filename:null,
      loadingExcel: 0,


      // paginations
      isPage: 0,
      page: 1,
      page_tmp: null,
      limits: 50,
      offsets: 0,
      pageLength: null,

      total_order: 0,
      branch_name: "",
    }),
    async created() {
      this.inv_no = null
      this.invGroup = null

      if(this.$route.query.inv === 'undefined'){
        this.inv_no = null
      } else if ( this.$route.query.inv == 'null') {
        this.inv_no = null
      } else if ( this.$route.query.inv == null) {
        this.inv_no = null
      } else if (this.$route.query.inv == '') {
        this.inv_no = null
      } else {
        this.inv_no = this.$route.query.inv
      }

      this.branch_id = this.$route.query.branch_id
      await this.get_invoice()
      this.page_loading = false
    },
    methods: {
      async get_count_all_order () {
        let res1 = await axios.post(reportService_dotnet+'HQInvoice/get-count-order-detail1', {
          "id": this.invGroup_id
        },
        { headers: header_token})
        // console.log(res1.data);
        this.total_order = res1.data
        this.getpagelength(res1.data)
      },

      async get_invoice () {

        try {
          let res2 = await axios.post(reportService_dotnet+'HQInvoice/get-invoice-detail-express', {
            "InvoiceNo": this.inv_no,
            "limits": this.limits,
            "offsets": this.offsets
          },
          { headers: header_token})

          this.datalist = res2.data
          // console.log(this.datalist);
          // this.datalist_for_excel = [...this.datalist]

        } catch (e) {
          this.AlertError()
        }

      },

      async get_invoice_summary () {
        // console.log(limits, offsets);

        try {
          let res1 = await axios.post(reportService_dotnet+'HQInvoice/group-detail-summary', {
            "id": this.invGroup_id,
            "limits": this.total_order,
            "offsets": 0
          },
          { headers: header_token})
          this.data_group_detail = res1.data

        } catch (e) {
          this.AlertError()
        }

      },

      getpagelength (total_data) {
        var total = 0
        if(total_data != 0){
          total = total_data
        } else {
          total = 1
        }

        total = parseInt(total)
        this.pageLength = Math.ceil(total/this.limits)
        this.isPage = 0
        this.page_tmp = this.page
      },

      async changePage () {
        this.loading = true
        if (this.page >= 1 && this.page <= this.pageLength ) {
          if(this.page_tmp != this.page){
            this.offsets = (this.page*this.limits)-this.limits
            await this.get_invoice(this.limits, this.offsets)
            this.page_tmp = this.page
          }
        }
        this.loading = false
      },

      gen_num_data_table (index) {
        if(index != null ) {
          var start = null
          // if(this.selected == 30){
            start = 1 + this.offsets
            return (start + index)
          // }
          // else {
          //   start = this.total_search_data_order_master - this.offsets
          //   return (start - index )
          // }
        } else {
          return ""
        }
      },

      async loading_excel_percent () {
        this.loadingExcel = 1
        try {
          this.datalist_for_excel = []

          var percent = 1;
          var limits = 2000
          var total = this.total_order
          var offsets = 0
          var loadingExcel = 0

          var Round =parseInt( total / limits);
          if(limits*Round < total){
            Round=Round+1;
          }

          for (var i =1; i <=Round; i++) {
            percent =parseInt(i / Round * 100)

            var Ndata = await this.get_data_for_excel(limits,offsets)
            for (var jj in Ndata){
              this.datalist_for_excel.push(Ndata[jj])
            }
            // this.datalist_for_excel = this.datalist_for_excel.concat(Ndata);
            this.loadingExcel = percent
            offsets = offsets + limits;
          }


          if(this.datalist_for_excel.length > 0){
            this.datalist_for_excel.push({"vrichFee": "ค่าธรรมเนียม" , "totalPrice": this.datalist_for_excel[this.datalist_for_excel.length-1].transactionFee})
            this.datalist_for_excel.push({"vrichFee": "ค่าเชื่อมต่อ Vrich" , "totalPrice": this.datalist_for_excel[this.datalist_for_excel.length-1].transactionFee})
            this.datalist_for_excel.push({"vrichFee": "%VAT ค่าบริการ" , "totalPrice": this.data_group_detail.serviceVatPercent})
            this.datalist_for_excel.push({"vrichFee": "VAT ค่าบริการ" , "totalPrice": this.data_group_detail.serviceVatAmount})
            this.datalist_for_excel.push({"vrichFee": "รวมยอดทั้งหมด" , "totalPrice": this.data_group_detail.totalPrice})
          }

          await document.getElementById("GetExcel").click();
          this.datalist_for_excel = []
          this.loadingExcel = 0


        } catch (e) {
          this.AlertError()
          this.loadingExcel = 0
          this.datalist_for_excel = []
        }
      },

      async get_data_for_excel (limits, offsets) {
        let res2 = await axios.post(reportService_dotnet+'HQInvoice/group-detail1', {
          "id": this.invGroup_id,
          "limits": limits,
          "offsets": offsets
        },
        { headers: header_token})
        return res2.data
      },

      exportexceltracklist(type){
        if (type == 2) {
          this.excel_detail_invoice_list_header = [
                                                    { label: "TrackingNo", field: "tracking", width: 15},
                                                    { label: "กว้าง", field: "cubic1", width: 25 },
                                                    { label: "ยาว", field: "cubic2", width: 25 },
                                                    { label: "สูง", field: "cubic3", width: 15 },
                                                    { label: "ค่าบริการ cod", field: "codAmount", width: 15 },
                                                  ]
          this.filename = "รายงานใบแจ้งหนี้ค่าบริการ cod"
        }
        else if (type == 1) {
          this.excel_detail_invoice_list_header = [
                                                    { label: "TrackingNo", field: "tracking", width: 15},
                                                    { label: "กว้าง", field: "cubic1", width: 25 },
                                                    { label: "ยาว", field: "cubic2", width: 25 },
                                                    { label: "สูง", field: "cubic3", width: 15 },
                                                    { label: "ค่าบริการ cod", field: "expressAmount", width: 15 },
                                                  ]
          this.filename = "รายงานใบแจ้งหนี้ขนส่ง"
        }


        return this.excel_detail_invoice_list_header,this.filename
      },
      FormatInvoiceBranch,
      FormatOrder,
      format_price,
      AlertError,
    }
  }
</script>

<style scoped>
  .packhai-border-table{
    /* border:1px solid #e9edf4; */
    border-collapse: collapse;
    border-spacing: 0px;
    border-right: 1px solid #e9edf4;
    border-bottom: 1px solid #e9edf4;
    border-top: 1px solid #e9edf4;
  }
  .packhai-border-table thead{
    color:white;
  }
  .packhai-border-table thead tr th{
    color: black !important;
    font-weight: bold;
    font-size: 14px;
  }
  .packhai-border-table tr {
    /* border-collapse: collapse; */
    /* border: 1px solid #e9edf4; */
  }
  .packhai-border-table th {
    /* border-collapse: collapse; */
      border-left: 1px solid #e9edf4;
  }
  .packhai-border-table tr td{
    border-left: 1px solid #e9edf4;
  }
</style>
